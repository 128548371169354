<template>
  <el-card class="box-card">
    <el-form style="margin-top: 30px" ref="form" label-width="130px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="预售名称" prop="name">
        <el-input v-model="form.name" size="small" placeholder="请输入预售活动名称" style="width: 25%"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="支付定金时间" prop="deposit_time">
        <el-tooltip placement="top">
          <div slot="content">定金支付时间</div>
          <el-date-picker
              v-model="deposit_time"
              size="small"
              type="datetimerange"
              @change="getDepositTime"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              align="right"/>
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="支付尾款时间" prop="tail_time">
        <el-tooltip placement="top">
          <div slot="content">注意：当尾款支付时间结束，那么该预售活动也就结束</div>
          <el-date-picker
              v-model="tail_time"
              size="small"
              type="datetimerange"
              @change="getTailTime"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              align="right"/>
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="限购" prop="restrictions">
        <el-tooltip placement="top">
          <div slot="content">下单时每人最多购买数量限制，不填时不限制</div>
          <el-input-number type="number" v-model="form.restrictions" size="small" controls-position="right"
                           step-strictly/>
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="预计发货时间" prop="">
        <el-radio-group v-model="form.deliver_goods_choice" size="small" @change="deliveryTime">
          <el-radio-button :label="0">固定时间</el-radio-button>
          <el-radio-button :label="1">支付尾款后</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="fixed" style="margin-bottom: 20px" label="固定日期">
        <el-tooltip placement="top">
          <div slot="content">仅用于前台显示，不用于实际限制预售订单的发货业务</div>
          <el-date-picker
              v-model="form.fixed_time"
              type="date"
              size="small"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>
        </el-tooltip>
      </el-form-item>
      <el-form-item v-show="afterPayment" style="margin-bottom: 20px" label="支付尾款后">
        <el-tooltip placement="top">
          <div slot="content">仅用于前台显示，不用于实际限制预售订单的发货业务</div>
          <el-input-number v-model="form.days" size="small" controls-position="right" step-strictly/>
        </el-tooltip>
        <span>天</span>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否支持退定金" prop="refund_the_deposit">
        <el-radio v-model="form.refund_the_deposit" :label="1">是</el-radio>
        <el-radio v-model="form.refund_the_deposit" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动商品" prop="">
        <el-table :data="s_list" size="small" style="width: 70%"
                  @selection-change="setSelectRows">
          <el-table-column align="center" show-overflow-tooltip type="selection"/>
          <el-table-column
              align="center"
              label="商品名称"
              prop="name"
          />
          <el-table-column
              align="center"
              label="商品价格"
              prop="price"
          />
          <el-table-column
              align="center"
              label="预售库存"
              prop="inventory"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.inventory" v-model="s_list[scope.$index].inventory"
                        placeholder="请输入预售库存" :value="scope.row.inventory"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].inventory" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="定金"
              prop="deposit"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.deposit" v-model="s_list[scope.$index].deposit"
                        placeholder="请输入定金" :value="scope.row.deposit"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].deposit" placeholder="请输入内容" :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="定金可抵金额"
              prop="deposit_counteract"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.deposit_counteract"
                        v-model="s_list[scope.$index].deposit_counteract"
                        placeholder="请输入定金可抵抗金额" :value="scope.row.deposit_counteract"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].deposit_counteract" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button icon="el-icon-plus" type="primary" @click="showPop = true" size="small" style="margin-top: 10px">
          选择商品
        </el-button>
        <el-button v-if="s_list.length > 0" icon="el-icon-delete" type="danger" size="small" style="margin-top: 10px"
                   @click="handleDelete">批量删除
        </el-button>
        <el-button v-else icon="el-icon-delete" type="danger" size="small" style="margin-top: 10px" disabled>批量删除
        </el-button>
        <PopView :visible.sync="showPop" :chooseGoods="chooseGoods" title="选择商品" @confirm="showPopSubmit"></PopView>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="预售规则说明" prop="remark">
        <el-input
            type="textarea"
            :autosize="{ minRows: 2}"
            v-model="form.remark"
            style="width: 25%"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>

import PopView from "@/views/components/popView";
import {apiGetBookingInfo, apiSeckillInfo, apiUpdateBookingGoods} from "@/request/api";
import {formatDate} from "@/utils/theTimeStamp";

export default {
  components: {PopView},
  props: {
    chooseGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPop: false,
      list: [],
      s_list: [],
      final_payment: [],
      selectRows: '',
      fixed: true,
      afterPayment: false,
      deposit_time: [],
      tail_time: [],
      form: {
        id: '',//预售活动id
        name: '',//活动名称
        begin_time: '',//活动开始时间
        end_time: '',//活动结束时间
        pay_begin_time: '',//付款开始时间
        pay_end_time: '',//付款截止时间
        restrictions: 0,//限制购买数量，为0时不限制
        deliver_goods_choice: 0,//发货选择0固定1付款后
        fixed_time: '',//固定日期
        days: '',//天数
        refund_the_deposit: 1,//是否可以退还定金1:能0不能
        remark: '',//说明
        goods: [],
      },
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '活动名称不能为空'},
        ],
        restrictions: [
          {required: true, trigger: 'blur', message: '限购数量不能为空'},
        ],
        remark: [
          {required: true, trigger: 'blur', message: '活动说明不能为空'},
        ],
      },
    }
  },
  created() {
    if (this.$route.query.id) {
      this.seckillInfo()
    }
  },
  methods: {
    seckillInfo() {
      apiGetBookingInfo({id: this.$route.query.id}).then(res => {
        if (res.code == 200) {
          this.form = {
            id: res.data.id,//预售活动id
            name: res.data.name,//活动名称
            begin_time: this.formatDate(res.data.begin_time),//活动开始时间
            end_time: this.formatDate(res.data.end_time),//活动结束时间
            pay_begin_time: this.formatDate(res.data.pay_begin_time),//付款开始时间
            pay_end_time: this.formatDate(res.data.pay_end_time),//付款截止时间
            restrictions: res.data.restrictions,//限制购买数量，为0时不限制
            deliver_goods_choice: res.data.deliver_goods_choice,//发货选择0固定1付款后
            fixed_time: res.data.fixed_time,//固定日期
            days: res.data.days,//天数
            refund_the_deposit: res.data.refund_the_deposit,//是否可以退还定金1:能0不能
            remark: res.data.remark,//说明
            goods: this.getGoods(res.data.goods),
          }
          this.deposit_time = [this.formatDate(res.data.begin_time), this.formatDate(res.data.end_time)]
          this.tail_time = [this.formatDate(res.data.pay_begin_time), this.formatDate(res.data.pay_end_time)]
          console.log(this.deposit_time)
        }
      })
    },
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
    //未完成
    getGoods(arr) {
      this.list = []
      if (arr.length > 0) {
        arr.forEach(item => {
          this.list.push({
            sku_id: item.sku_id,
            inventory: 1,//预售库存
            deposit: 1,//定金
            deposit_counteract: 1,//定金抵扣
          })
          this.s_list.push({
            sku_id: item.sku_id,
            imgUrl: item.sku_info.imgUrl,//商品图片
            name: item.sku_info.name,//商品名
            price: item.sku_info.price,//价格
            inventory: item.inventory,//预售库存
            deposit: item.deposit,//定金
            deposit_counteract: item.deposit_counteract,//定金抵扣
          })
        })
        return this.list
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    showPopSubmit(e) {
      this.showPop = true;
      this.listLoading = true
      // this.list = this.form.goods
      console.log(e)
      e.forEach(item => {
        this.s_list.push({
          sku_id: item.skuid,
          name: item.name,
          price: item.price,
          inventory: 1,//预售库存
          deposit: 1,//定金
          deposit_counteract: 1,//定金抵扣
        })
      })
      const smap = new Map()
      // 对id属性进行去重
      this.s_list = this.s_list.filter(key => !smap.has(key.sku_id) && smap.set(key.sku_id, 1))
      this.listLoading = false
      console.log(this.s_list)
    },
    handleDelete(row) {
      if (row.sku_id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.s_list.map((val, i) => {
            if (val.sku_id == row.sku_id) {
              this.s_list.splice(i, 1)
            }
          })
        })
      } else {
        this.$confirm('你确定要删除所有选中项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (var j = 0; j < this.selectRows.length; j++) {
            this.s_list.map((val, i) => {
              if (val.sku_id == this.selectRows[j].sku_id) {
                this.s_list.splice(i, 1)
              }
            })
          }
        })
      }
    },
    //获取时间段
    getDepositTime(event) {
      if (event != null) {
        this.form.begin_time = event[0]
        this.form.end_time = event[1]
      } else {
        this.form.begin_time = ''
        this.form.end_time = ''
      }
    },
    getTailTime(event) {
      if (event != null) {
        this.form.pay_begin_time = event[0]
        this.form.pay_end_time = event[1]
      } else {
        this.form.pay_begin_time = ''
        this.form.pay_end_time = ''
      }
    },
    // 选择达标时间周期
    deliveryTime(event) {
      console.log(event)
      if (event == 1) {
        this.fixed = false
        this.afterPayment = true
        this.form.fixed_time = ''
      } else {
        this.fixed = true
        this.afterPayment = false
        this.form.days = ''
      }
    },
    //清空表单
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.deposit_time = []
      this.tail_time = []
      this.s_list = []
    },
    async submit() {
      this.list = []
      if (this.s_list.length > 0) {
        this.s_list.forEach(item => {
          this.list.push({
            sku_id: item.sku_id,
            inventory: item.inventory,//预售库存
            deposit: item.deposit,//定金
            deposit_counteract: item.deposit_counteract,//定金抵扣
          })
        })
      } else {
        this.$message.error('参与活动商品不能为空')
        return
      }
      const map = new Map()
      this.form.goods = this.list.filter(key => !map.has(key.sku_id) && map.set(key.sku_id, 1))
      apiUpdateBookingGoods(this.form).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.close()
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}

</script>
